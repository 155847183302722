import React from 'react'
import '../node_modules/@regulaforensics/vp-frontend-face-components-beta/dist/main.js'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Gleap from 'gleap'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

// Please make sure to call this method only once!
if (process.env.NODE_ENV === 'production' && window.innerWidth > 768) {
  Gleap.initialize('9HkyRf8HxGAHVtzzNGltdnTKBu7OeSk1')
}

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (process.env.NODE_ENV === 'production') {
  root.render(<App />)
} else {
  root.render(<App />)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
