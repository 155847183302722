import React, { ReactElement } from 'react'
import configStore from '../../stores/config.store'
import Checkbox from '../checkbox/Checkbox'

const TermsOfService: React.FC = (): ReactElement => {
  const termsOfServiceUrl = configStore.config?.termsOfServiceURL
    ? configStore.config?.termsOfServiceURL
    : process.env.REACT_APP_DEFAULT_TERMS_OF_SERVICE

  const privacyPolicyUrl = configStore.config?.privacyPolicyURL
    ? configStore.config?.privacyPolicyURL
    : process.env.REACT_APP_DEFAULT_PRIVACY_POLICY

  const generateText = (): ReactElement => {
    return (
      <>
        I agree to the{' '}
        <a
          href={termsOfServiceUrl}
          target="_blank"
          rel="noreferrer"
          className="underline cursor-pointer"
        >
          terms of service
        </a>{' '}
        and{' '}
        <a
          href={privacyPolicyUrl}
          target="_blank"
          rel="noreferrer"
          className="underline cursor-pointer"
        >
          privacy policy
        </a>
        .
      </>
    )
  }

  return (
    <div className="flex">
      <Checkbox
        variable="isTermsAndConditionsAccepted"
        className="mt-xs mr-2"
        text={generateText()}
      />
    </div>
  )
}

export default TermsOfService
