import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { Node, UpdateNode } from './dto/Organigram'

import AddPersonFlow from './AddPersonFlow/AddPersonFlow'
import { NodeType } from '../../../dto/CreateNode.dto'
import { observer } from 'mobx-react'
import { FlowType } from '../../../dto/User.interface'
import AddCompanyFlow from './AddCompanyFlow/AddCompanyFlow'
import kybStore from '../../../stores/kyb.store'
import FlowDecide from './DecideFlow/FlowDecide'
import VerificationStepType from '../../../enums/VerificationStepType'

interface AddNodeModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  addNode: (node: Node | UpdateNode) => void
  readonly?: boolean
  initialStep?: VerificationStepType
}

enum FLOW {
  ADD_COPERATE,
  ADD_PERSON,
  DECIDE
}

const AddNodeModal = ({
  open,
  setOpen,
  addNode,
  readonly = false,
  initialStep
}: AddNodeModalProps): ReactElement => {
  const [flow, setFlow] = useState(FLOW.DECIDE)

  useEffect(() => {
    const { currentNode } = kybStore
    if (currentNode) {
      if (currentNode.type === NodeType.LEGAL_PERSON) {
        setFlow(FLOW.ADD_COPERATE)
      }
      if (currentNode.type === NodeType.NATURAL_PERSON) {
        setFlow(FLOW.ADD_PERSON)
      }
    }
  }, [kybStore.currentNode])

  const renderFlow = (): ReactElement => {
    if (flow === FLOW.ADD_PERSON) {
      return (
        <AddPersonFlow
          readonly={readonly}
          initialStep={initialStep}
          close={() => {
            setOpen(false)
            setFlow(FLOW.DECIDE)
          }}
          addNode={(node) => {
            addNode(node)
          }}
        />
      )
    } else if (flow === FLOW.ADD_COPERATE) {
      return (
        <AddCompanyFlow
          initialStep={initialStep}
          readonly={readonly}
          close={() => {
            setOpen(false)
            setFlow(FLOW.DECIDE)
          }}
          addNode={(node) => {
            addNode(node)
          }}
        />
      )
    } else {
      return (
        <FlowDecide
          onClose={() => {
            setFlow(FLOW.DECIDE)
            setOpen(false)
          }}
          onChoosePerson={() => {
            addNode({
              id: '',
              status: 'PENDING',
              type: NodeType.NATURAL_PERSON,
              user: {
                flowType: FlowType.KYB_USER
              },
              children: []
            })
            setFlow(FLOW.ADD_PERSON)
          }}
          onChooseCompany={() => {
            addNode({
              id: '',
              status: 'PENDING',
              type: NodeType.LEGAL_PERSON,
              user: {
                flowType: FlowType.KYB_COMPANY
              },
              children: []
            })
            setFlow(FLOW.ADD_COPERATE)
          }}
        />
      )
    }
  }

  return (
    <div>
      {open && (
        <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full shadow-md rounded-t-md border-t pt-3xl modal-bg-color"></div>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-700 sm:duration-700"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div className="absolute bottom-0 left-0 right-0 top-0 w-full  shadow-md rounded-t-md border-t pt-16">
            <div className="bg-white h-full rounded-md flex flex-col flex-grow">
              {renderFlow()}
            </div>
          </div>
        </Transition.Child>
      </Transition.Root>
    </div>
  )
}

export default observer(AddNodeModal)
