import InputOptionType from '../enums/InputOptionType'

const getInputOptions = (type?: InputOptionType): any => {
  const textPattern = {
    value: /^[A-Za-zöäü ,.'-]+/,
    message: 'Invalid Text'
  }
  const numberPattern = {
    value: /[0-9-]/,
    message: 'Invalid Number'
  }

  const textAndNumberPattern = {
    value: /^[a-zA-Z0-9_.-]*$/,
    message: 'Invalid Number'
  }

  const emailPattern = {
    value:
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    message: 'Invalid email address'
  }

  switch (type) {
    case InputOptionType.TEXT:
      return {
        required: true,
        pattern: textPattern
      }
    case InputOptionType.NOT_REQUIRED_TEXT:
      return {
        pattern: textPattern
      }
    case InputOptionType.NUMBER:
      return {
        required: true,
        pattern: numberPattern
      }
    case InputOptionType.NOT_REQUIRED_NUMBER:
      return {
        pattern: numberPattern
      }
    case InputOptionType.TEXT_AND_NUMBER:
      return {
        required: true,
        pattern: textAndNumberPattern
      }
    case InputOptionType.NOT_REQUIRED_TEXT_AND_NUMBER:
      return {
        pattern: textAndNumberPattern
      }
    case InputOptionType.EMAIL:
      return {
        required: true,
        pattern: emailPattern
      }
    case InputOptionType.NOT_REQUIRED_EMAIL:
      return {
        pattern: emailPattern
      }
    default:
      return {}
  }
}

export { getInputOptions }
