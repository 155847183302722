import React, { ReactElement } from 'react'
import * as Flags from 'country-flag-icons/react/3x2'

const CustomCountryOption = ({
  data,
  innerProps,
  children,
  restricted
}: any): ReactElement => {
  const Flag: any = (Flags as any)[data.value]
  if (restricted && restricted.includes(data.value)) {
    // disable click on restricted countries
    innerProps.onClick = () => {}
    return (
      <div
        {...innerProps}
        className="p-2 text border-b border-l border-r cursor-not-allowed"
      >
        <div className="opacity-50 flex flex-row items-center justify-between">
          <div className="flex items-center">
            <Flag className="w-4 h-4 mr-2" />
            {data.label}
          </div>
          <div className="text-red-400">Restricted</div>
        </div>
      </div>
    )
  }
  return (
    <div
      {...innerProps}
      className="flex flex-row items-center p-2 text border-b border-l border-r cursor-pointer hover:text-primary"
    >
      <Flag className="w-4 h-4 mr-2" />
      {data.label}
    </div>
  )
}

export default CustomCountryOption
